import { defineStore } from "pinia";
import { InvestmentsDocument, NoticeDocument, SubscriptionItem } from "~/types/domain/domain";
import { i18n } from '~/utils/i18n'
import { useInvestmentsStore } from "./investments";

export type DocumentView = 'ME' | 'INVESTMENTS' | 'SUBSCRIPTION' | 'NOTICES'
export type FilterType = keyof State['selectedFilters'];

export interface State {
  currentViewType: DocumentView
  user: {
    documents: UserDocument[]
    documentsRequested: DocumentRequested[]
    subscriptions: SubscriptionItem[]
  }
  investments: {
    documents: InvestmentsDocument[]
  },
  notices: {
    documents: NoticeDocument[]
  },
  uploadDocumentModal: {
    isOpened: boolean
    document: DocumentRequested | UserDocument
    isNewDocument: boolean
  }
  selectedFilters: DocumentsFilters
  documentViewerModal: {
    isOpened: boolean
    url: string
  }
}

export interface UserDocument {
  id?: string
  investorId?: string
  ressourceId: string
  type: string
  label: string
  file: string
  date: {
    value: string
    display: string
  }
}

export interface DocumentRequested {
  id?: string
  investorId?: string
  title: string
  author: string
  message?: string
  type?: string
  date: {
    value: string
    display: string
  }
}

export interface DocumentsFilters {
  categories: DocumentFilterItem[]
  years: DocumentFilterItem[]
  documents: DocumentFilterItem[]
  funds: DocumentFilterItem[]
  status: DocumentFilterItem[]
}

export interface DocumentFilterItem {
  value: string
  name: string
}

export const useDocumentsStore = defineStore('documents', {
  state: (): State => ({
    currentViewType: 'ME',
    user: {
      documents: [],
      documentsRequested: [],
      subscriptions: []
    },
    investments: {
      documents: []
    },
    notices: {
      documents: []
    },
    uploadDocumentModal: {
      isOpened: false,
      document: null,
      isNewDocument: false
    },
    selectedFilters: {
      categories: [],
      years: [],
      documents: [],
      funds: [],
      status: []
    },
    documentViewerModal: {
      isOpened: false,
      url: ''
    }
  }),
  actions: {
    setCurrentViewType (payload: DocumentView) {
      this.currentViewType = payload
    },
    resetUploadDocumentModal () {
      this.uploadDocumentModal = {
        isOpened: false,
        document: null,
        isNewDocument: false
      }
    },
    resetDocumentViewerModal () {
      this.documentViewerModal = {
        isOpened: false,
        url: ''
      }
    },
    removeFilter (filterType: FilterType, value: string) {
      this.selectedFilters[filterType] = this.selectedFilters[filterType].filter(filter => filter.value !== value)
    },
    resetSelectedFilters () {
      this.selectedFilters = {
        categories: [],
        years: [],
        documents: [],
        funds: [],
        status: []
      }
    },
    updateNoticeDocument (document: NoticeDocument) {
      this.notices.documents = this.notices.documents.map(d => d.id === document.id ? document : d)
    },
    setNoticeDocumentRead (noticeId: string) {
      const document = this.notices.documents.find(d => d.id === noticeId)
      if (document) {
        this.updateNoticeDocument({...document, status: 'READ'})
      }
    }
  },
  getters: {
    getUsersDocuments (state): UserDocument[] {
      return state.user.documents
    },
    userHasRequestedDocuments (state): boolean {
      return state.user.documentsRequested.length > 0
    },
    userHasDocuments (state): boolean {
      return state.user.documents.length > 0
    },
    getInvestmentsDocuments (state): InvestmentsDocument[] {
      let documents = state.investments.documents

      Object.entries(state.selectedFilters).forEach(([filterType, filters]) => {
        if (filters.length > 0) {
          const values = filters.map(filter => filter.value)
          switch (filterType) {
            case 'categories':
              documents = documents.filter(document => values.includes(document.fund.categoryId))
              break
            case 'years':
              documents = documents.filter(document => values.includes(document.year))
              break
            case 'documents':
              documents = documents.filter(document => values.includes(document.type))
              break
            case 'funds':
              documents = documents.filter(document => values.includes(document.fund.id))
              break
            default:
              break
          }
        }
      })

      return documents
    },
    hasNoticeDocuments (state): boolean {
      return state.notices.documents.length > 0
    },
    numberOfUnreadNoticeDocuments (state): number {
      return state.notices.documents.filter(document => document.status.toUpperCase() === 'SENT').length
    },
    getNoticeDocuments (state): NoticeDocument[] {
      let documents = state.notices.documents

      Object.entries(state.selectedFilters).forEach(([filterType, filters]) => {
        if (filters.length > 0) {
          const values = filters.map(filter => filter.value)
          switch (filterType) {
            case 'categories':
              documents = documents.filter(document => values.includes(document.fund.categoryId))
              break
            case 'years':
              documents = documents.filter(document => values.includes(document.year))
              break
            case 'documents':
              documents = documents.filter(document => values.includes(document.type))
              break
            case 'funds':
              documents = documents.filter(document => values.includes(document.fund.id))
              break
            case 'status':
              documents = documents.filter(document => values.includes(document.status))
              break
            default:
              break
          }
        }
      })

      return documents
    },
    filters (state): DocumentsFilters {
      const investmentsStore = useInvestmentsStore()
      const categoriesList = investmentsStore.categoriesList
      const categories: DocumentFilterItem[] = [], funds: DocumentFilterItem[] = [],
            documents: DocumentFilterItem[] = [], years: DocumentFilterItem[] = [],
            status: DocumentFilterItem[] = []


      switch (state.currentViewType) {
        // case 'ME':
        //   break
        // case 'INVESTMENTS':
        //   break
        // case 'SUBSCRIPTION':
        //   break
        case 'NOTICES':
          state.notices.documents.forEach(document => {
            const category = categoriesList.find(c => c.id === document.fund.categoryId)
            if (category) {
              categories.push({
                value: category.id,
                name: category.name
              })
            }
            funds.push({
              value: document.fund.id,
              name: document.fund.name
            })

            documents.push({
              value: document.type,
              name: i18n.t(`documents.types.${document.type}`)
            })

            years.push({
              value: document.year,
              name: document.year
            })

            status.push({
              value: document.status.toUpperCase(),
              name: i18n.t(`documents.status.notice.${document.status.toUpperCase()}`),
            })
          })
          break
        default:
          state.investments.documents.forEach(document => {
            const category = categoriesList.find(c => c.id === document.fund.categoryId)
            if (category) {
              categories.push({
                value: category.id,
                name: category.name
              })
            }
            funds.push({
              value: document.fund.id,
              name: document.fund.name
            })

            documents.push({
              value: document.type,
              name: i18n.t(`documents.types.${document.type}`)
            })

            years.push({
              value: document.year,
              name: document.year
            })
          })
          break
      }


      const filters = {
        categories,
        years,
        documents,
        funds,
        status,
      }
      type FilterKey = keyof typeof filters;
      Object.keys(filters).forEach((key) => {
        const filterKey = key as FilterKey;
        // remove duplicates
        filters[filterKey] = [...new Set(filters[filterKey].filter(item => !!item.name))];
        filters[filterKey] = filters[filterKey].filter((item, index, self) => self.findIndex(i => i.value === item.value) === index);
        // sort by name
        filters[filterKey].sort((a, b) => a.name.localeCompare(b.name));
      });

      return filters
    },
    isDocumentsFiltered (): boolean {
      return Object.values(this.selectedFilters).some((filter: DocumentFilterItem[]) => filter.length > 0)
    }
  }
})
